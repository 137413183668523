import { TYPE_CHIEF_ADJUDICATOR, TYPE_CONVENOR, TYPE_DEBATOR, TYPE_TAB_MASTER } from "../api/constants";
import useAuth from "../hooks/useAuth";
import { ITournament } from "../pages/Tournaments/TournamentsList/Tournament.interface";


export const IsThisTournametTabMaster = (tournament: ITournament) => {
  return [TYPE_TAB_MASTER, TYPE_CONVENOR].includes(tournament?.participant_details?.type);
};


export const IsTournamentDebator = (tournament: ITournament) => {
  return [TYPE_DEBATOR].includes(tournament?.participant_details?.type);
}

export const CanChangeParticipantRole = (tournament: ITournament) => {
  return [TYPE_TAB_MASTER, TYPE_CONVENOR].includes(tournament?.participant_details?.type);
}

export const IsSuperUser = () => {
  const { user } = useAuth();  
  return user?.is_superuser || false
}


export const IsThisTournametCA = (tournament: ITournament) => {
  return [TYPE_CHIEF_ADJUDICATOR, TYPE_CONVENOR, TYPE_TAB_MASTER].includes(tournament?.participant_details?.type);
};
