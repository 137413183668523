import {
  CONFLICT_STATUS_APPROVED,
  CONFLICT_STATUS_CANCELED,
  CONFLICT_STATUS_REJECTED,
  CONFLICT_STATUS_REVOKED,
  PURPOSE_CLOSING_GOVERNMENT,
  PURPOSE_CLOSING_OPPOSITION,
  PURPOSE_OPENING_GOVERNMENT,
  PURPOSE_OPENING_OPPOSITION,
  STATUS_PENDING,
} from "../api/constants";
import { IBallotRank } from "../pages/Tournaments/Rounds/Ballot/Ballot.interface";
import { IRoundDraw } from "../pages/Tournaments/Rounds/RoundDraws/RoundDraws.interface";
import { IMatchTeam } from "../pages/Tournaments/Teams.interface";
import { IUser } from "../pages/Users/Users.interface";

export const beautifyName = (user: IUser | undefined) => {
  return (
    `${user?.first_name} ${user?.last_name}` +
    (user?.other_name ? ` ${user?.other_name}` : "")
  );
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getMatchTeamByPurpose = (purpose: string, teams: IMatchTeam[]) => {
  return teams.find((team) => team.purpose === purpose);
};

export const filterTeamByPurpose = (purpose: string, teams: IMatchTeam[]) => {
  return teams.filter((team) => team.purpose === purpose);
};

export const sortTeams = (teams: IBallotRank[]): IBallotRank[] => {
  const ranked = teams.sort((a, b): any => {
    if (a.score === b.score) {
      return b.margin - a.margin;
    } else {
      return b.score - a.score;
    }
  });

  const teamsWithScore = ranked.map((team, index) => ({
    ...team,
    points: ranked.length - (index + 1),
    margin: ranked[0].score - team.score,
    position: index+1
  }));

  return teamsWithScore;
};

export const getOrdinalSuffix = (n: number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = n % 100; // Consider last two digits for special cases like 11, 12, 13
  if (value > 10 && value < 20) {
    return n + suffixes[0]; // 'th' for 11th, 12th, 13th, etc.
  }
  const lastDigit = n % 10;
  return n + (suffixes[lastDigit] || suffixes[0]); // Append 'st', 'nd', 'rd', or 'th'
};

export const getStatusClass = (type: string) => {
  switch (type) {
    case "completed":
    case "in_progress":
    case "active":
      return "span-green";
    case "initial":
    case "draws_not_released":
    case "unavailable":
      return "span-orange";
    case "started":
      return "span-blue";
  }
};

export const getActionMessage = (action: string) => {
  switch (action) {
    case CONFLICT_STATUS_APPROVED:
      return "approve";
    case CONFLICT_STATUS_REJECTED:
      return "reject";
    case CONFLICT_STATUS_CANCELED:
      return "cancel";
    case CONFLICT_STATUS_REVOKED:
      return "revoke";
    default:
      return "";
  }
};

export function stringify(text: any) {
  return text?.replaceAll?.("_", " ")?.replaceAll("-", " ");
}

export function getStatusColor(status: any) {
  let color = "green-inverse";
  switch (status?.toLowerCase()) {
    case STATUS_PENDING:
    case "registration":
      color = "gold-inverse";
      break;

    default:
      break;
  }
  return color;
}


export const prepareObjectFormdataPost = (object: Record<string, string>, field: string) => {
  let extras = {};
  Object.keys(object).forEach((key) => {
    extras = { ...extras, [`${field}.${key}`]: object[key] }
  });
  return extras;
};


export const createFormData = (object: Record<string, string>) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key]);
  });
  return formData;
};

export const checkDrawError = (match_team: IMatchTeam[]) => {
  let error = false;
  // If there is any side that misses  a team or has more than one team, show error
  [PURPOSE_CLOSING_GOVERNMENT, PURPOSE_CLOSING_OPPOSITION, PURPOSE_OPENING_GOVERNMENT, PURPOSE_OPENING_OPPOSITION].map(purpose => {
    const teams = filterTeamByPurpose(purpose, match_team)
    if (teams.length !== 1) {
      error = true;
    }
  });
  return error
}

export const roundHasNoErrors = (draws: IRoundDraw[]) => {
  return draws.find(draw => draw.has_error)? false: true;
}
